.CustomLayoutSelection {
  display: flex;
  flex-direction: column;
  .CustomLayoutSelection__Selection {
    overflow-y: auto;
  }

  .CustomLayoutSelection__Controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
    .CustomLayoutSelection__Button {
        padding: 7px 30px;
        background: #B4B4B4;
        color: #fff;
        font-weight: bold;
        border-radius: 10px;
        border: 0;
        cursor: pointer;
    }
  }
}
