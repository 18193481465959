.HomeContainer {
  display: flex;
  align-items: stretch;
  justify-content: center;
  height: 100%;

  // First step
  .property-type {
    display: flex;
    width: 100%;
    height: 100%;

    .card-radio-container {
      display: flex;
      width: 100%;
      height: 100%;

      .card-radio-container-item {
        height: fit-content;
        flex: 1 1;
        cursor: pointer;
        width: 50%;
        margin: 0 10px;
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        text-align: center;
        &:after {
          background: rgba(0, 0, 0, 0.3);
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        img {
          width: 100%;
        }

        span {
          font-size: 22px;
          z-index: 44;
          position: relative;
          color: #fff;
          font-weight: bold;
          line-height: 30px;
          margin: 0 auto;
          position: absolute;
          top: 20px;
          left: 0;
          right: 0;

          @media screen and (min-width: 1440px) {
            font-size: 24px;
          }
          @media screen and (max-width: 1600px) and (min-width: 1440px) {
            font-size: 25px;
          }
          @media screen and (max-width: 1920px) and (min-width: 1600px) {
            font-size: 26px;
          }
        }

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }

        &.checked {
          &::after {
            background: rgba(58, 184, 93, 0.62);
          }
          span {
            z-index: 44;
          }
        }

        input {
          display: none;
        }
      }
    }
  }

  // Second step
  .layoutType {
    .card-list-container {
      .CustomLayoutSelection {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        .CustomLayoutSelection_Selection {
          overflow-y: auto;
        }
      }
      .card-controls {
        flex: 1;
        .Button {
          margin-bottom: 0;
          line-height: 18px;
        }
      }
    }
    .Card-content {
      overflow: hidden;
      .RoomListDefinitions {
        overflow-y: auto;
      }
      .card-controls {
        flex: 1;
      }
    }
  }

  // Third step
  .card-checkbox-container {
    // display: flex;
    // flex-direction: column;
    width: 100%;
    cursor: pointer;
    // align-items: center;
    // justify-self: center;

    .card-checkbox-container-item {
      width: 100%;
      height: 110px;
      overflow: hidden;
      display: flex;
      font-weight: bold;
      margin-bottom: 20px;
      padding: 30px;
      font-size: 21px;
      line-height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      color: #fff;

      @media screen and (max-width: 1600px) and (min-width: 1440px) {
        font-size: 26px;
        height: 140px;
        margin-bottom: 35px;
      }

      @media screen and (max-width: 1920px) and (min-width: 1600px) {
        font-size: 30px;
        height: 160px;
        margin-bottom: 40px;
      }

      &:last-child {
        margin-bottom: 0;
      }
      &.shutter {
        background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
          url('./assets/device-types/shutter.png');
        &.checked {
          background: linear-gradient(
              rgba(58, 184, 93, 0.62),
              rgba(58, 184, 93, 0.62)
            ),
            url('./assets/device-types/shutter.png');
        }
      }
      &.lightning {
        background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
          url('./assets/device-types/lighting.png');
        &.checked {
          background: linear-gradient(
              rgba(58, 184, 93, 0.62),
              rgba(58, 184, 93, 0.62)
            ),
            url('./assets/device-types/lighting.png');
        }
      }
      &.heating {
        background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
          url('./assets/device-types/heating.png');
        &.checked {
          background: linear-gradient(
              rgba(58, 184, 93, 0.62),
              rgba(58, 184, 93, 0.62)
            ),
            url('./assets/device-types/heating.png');
        }
      }
    }
  }
}
