$cardWidth: 200px;
$lightGreen: #66c880;

.BOMContainer {
  border-radius: 30px;
  background: #f5f5f5;
  border: 1px solid #707070;
  padding: 10px 20px;
  margin: 10px;
  height: 100%;
  overflow-y: auto;

  .accessories-container {
    display: none;
  }

  .BOMContainer__Controls {
    .Button.Button--Secondary.Button--lg {
      display: block;
    }
  }

  @media screen and (min-width: 1440px) {
    .accessories-container {
      display: block;
    }
    .BOMContainer__Controls {
      .Button.Button--Secondary.Button--lg {
        display: none;
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    span {
      flex: 1;
      font-size: 20px;
      line-height: 28px;
      font-weight: bold;
      width: 100%;
      @media screen and (min-width: 1440px) {
        font-size: 22px;
        line-height: 30px;
      }
      @media screen and (max-width: 1600px) and (min-width: 1440px) {
        font-size: 24px;
        line-height: 32px;
      }
      @media screen and (max-width: 1920px) and (min-width: 1600px) {
        font-size: 26px;
        line-height: 34px;
      }
    }
    margin-bottom: 20px;
  }
  .BOMContainer__Controls {
    display: flex;
    align-items: center;
    justify-content: center;
    .Button {
      margin-bottom: 0;
    }
  }

  .RecommendedProducts {
    display: flex;
    // background: #fff;
    padding: 10px;
    width: 100%;
    flex-wrap: wrap;
  }
  .row {
    display: flex;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    .Selection {
      padding-right: 15px;
      border-right: 1px solid black;
      margin-right: 15px;
      .Card {
        background: #fff;
        border: 2px solid $lightGreen;
        max-width: $cardWidth;
        width: 100%;
        .Card-content {
          overflow: hidden;
          flex-direction: column;
        }
      }
    }
  }

  .Products {
    display: block;
    width: 100%;
    flex: 1;

    .Card {
      max-width: $cardWidth;
      background: #fff;
      display: inline-block;
      width: 100%;
      max-width: $cardWidth;
      margin-bottom: 15px;
      height: 350px;
      float: left;
      border: 0;
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }

    .Card-content {
      flex-direction: column;
      overflow: hidden;

      .BOM__Product {
        display: block;
        width: 100%;
        margin-bottom: 20px;

        .BOM__Product__Quantity {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          .BOM__Product__Quantity__Decrease {
            width: 20px;
            height: 20px;
            background: #15973f;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            cursor: pointer;
            border: 0;
          }

          .BOM__Product__Quantity__Value {
            margin-left: 5px;
            margin-right: 5px;
            color: #707070;
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
          }

          .BOM__Product__Quantity__Increase {
            width: 20px;
            height: 20px;
            cursor: pointer;
            background: #15973f;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            border: 0;
          }
        }

        .BOM__Product__Image {
          width: 120px;
          height: 120px;
          margin: 0 auto;
          margin-bottom: 10px;

          img {
            width: 100%;
          }
        }

        .BOM__Product__Title {
          font-size: 18px;
          line-height: 24px;
          min-height: 48px;
          color: #707070;
          margin-bottom: 10px;
          text-align: center;
          font-weight: 400;
        }

        .BOM__Product__Reference {
          font-size: 11px;
          line-height: 15px;
          color: #707070;
          margin-bottom: 10px;
          text-align: center;
        }

        .BOM__Product__Description {
          font-size: 11px;
          line-height: 15px;
          min-height: 60px;
          color: #707070;
          margin-bottom: 10px;
          text-align: center;
          @media screen and (max-width: 1600px) and (min-width: 1440px) {
            font-size: 12px;
          }
          @media screen and (max-width: 1920px) and (min-width: 1600px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
