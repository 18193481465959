$gray: #707070;
.SimpleSelect {
  width: 100%;
  margin-top: 20px;

  .SimpleSelect__Option {
    font-size: 20px;
    line-height: 33px;
    font-weight: bold;
    color: $gray;
    width: 100%;
    border-radius: 100px;
    padding: 15px 10px;
    text-align: center;
    border: 2px solid $gray;
    cursor: pointer;
    margin-bottom: 30px;

    &--Active {
      color: #fff;
      background: #3ab85d;
      border-color: #3ab85d;
    }
  }
}
