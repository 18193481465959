$green: #6aca5f;
.BrandPicker__Item {
  width: 30px;
  height: 30px;
  border: 50px;
  border: 1px solid transparent;

  .BrandPicker__Item__Image {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .BrandPicker__Item__Button {
    margin-top: 10px;
    margin-bottom: 10px;
    button {
      border: 2px solid #fff;
      padding: 5px 20px;
      color: #fff;
      background: transparent;
      border-radius: 10px;
      font-weight: bold;
    }
  }

  &.BrandPicker__Item--Active {
    .BrandPicker__Item__Button {
      button {
        background: #fff;
        color: $green;
      }
    }
  }
}
