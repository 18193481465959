.ReactModalPortal {
  .ReactModal__Overlay {
    &.ReactModal__Overlay--after-open {
      .ReactModal__Content {
        background: #f5f5f5 !important;
        border-radius: 32px !important;
        border: 0 !important;
        &.ReactModal__Content--after-open {
          &#RoomModal {
            display: flex;
            flex-direction: column;
            height: 100%;
            max-height: calc(100vh - 80px);
            overflow: hidden !important;

            .RoomModal__Header {
              display: flex;

              .RoomModal__Header__Title {
                display: flex;
                align-items: center;
                font-size: 32px;
                line-height: 43px;
                font-weight: bold;
                color: #000;
                flex: 1;

                svg {
                  margin-right: 20px;
                }
              }

              .RoomModal__Header__Right {
                button {
                  background: none;
                  border: 0;
                }
              }
            }

            .RoomModal__Body {
              display: flex;
              flex: 1;
              flex-direction: column;
              overflow-y: auto;

              .RoomItemsContainer {
                flex: 1;
                width: 100%;
                overflow-y: scroll;
              }

              .RoomItems {
                max-width: 1240px;
                width: 100%;
                margin: 0 auto;
                display: grid;
                gap: 30px 80px;
                grid-template-columns: 1fr 1fr 1fr;
              }
            }

            .RoomModal__Controls {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
