.BrandSelector {
  height: 350px;
  max-width: 425px;
  width: 100%;
  float: left;
  margin-bottom: 15px;
  margin-right: 10px;
  margin-left: 10px;

  position: relative;
  overflow-y: inherit;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transform: rotateY(0deg);

  .BrandSelector__Header {
    text-align: center;
    font-weight: bold;
    padding: 10px;
    display: flex;
    align-items: center;
    .BrandSelector__Header__Title {
      font-size: 24px;
      line-height: 26px;
      flex: 1;
    }
    .BrandSelector__Header__Close {
      button {
        color: #fff;
        background: none;
        border: 0;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .BrandSelector__DeviceType {
    line-height: 14px;
    font-size: 10px;
    // min-height: 82px;
    color: #696969;
    margin: 0;
  }
  .BrandSelector__ProductContainer {
    padding: 20px;
    transform: rotateY(0deg);
    background: #fff;
    border-radius: 30px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    .BrandSelector__Products {
      display: flex;
      align-items: center;
      justify-content: center;

      .BrandSelector__Product {
        display: inline-block;
        width: 100%;
        // margin-bottom: 20px;
        max-width: 190px;

        .BrandSelector__Product__Quantity {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          .BrandSelector__Product__Quantity__Decrease {
            width: 20px;
            height: 20px;
            background: #15973f;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            cursor: pointer;
            border: 0;
          }

          .BrandSelector__Product__Quantity__Value {
            margin-left: 5px;
            margin-right: 5px;
            color: #707070;
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
          }

          .BrandSelector__Product__Quantity__Increase {
            width: 20px;
            height: 20px;
            cursor: pointer;
            background: #15973f;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            border: 0;
          }
        }

        .BrandSelector__Product__Image {
          width: 120px;
          height: 120px;
          margin: 0 auto;
          margin-bottom: 10px;

          img {
            width: 100%;
          }
        }

        .BrandSelector__Product__Title {
          font-size: 18px;
          line-height: 24px;
          min-height: 48px;
          color: #707070;
          margin-bottom: 10px;
          text-align: center;
          font-weight: 400;
        }

        .BrandSelector__Product__Reference {
          font-size: 11px;
          line-height: 15px;
          color: #707070;
          margin-bottom: 10px;
          text-align: center;
        }

        .BrandSelector__Product__Description {
          font-size: 11px;
          line-height: 15px;
          min-height: 60px;
          color: #707070;
          margin-bottom: 10px;
          text-align: center;
          @media screen and (max-width: 1600px) and (min-width: 1440px) {
            font-size: 12px;
          }
          @media screen and (max-width: 1920px) and (min-width: 1600px) {
            font-size: 12px;
          }
        }
      }
    }
    .BrandSelector__Controls {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      label {
      }
    }
  }

  .BrandSelector__Configuration {
    transform: rotateY(180deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 30px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #6aca5f;
    padding: 10px;

    .BrandSelector__Configuration__Title {
      color: #fff;
      margin-bottom: 10px;
    }
    .BrandSelector__Header__Title {
      color: #fff;
    }

    .BrandSelector__Configuration__BrandPicker {
      display: flex;
      width: 100%;
      align-items: center;
      overflow: hidden;
      .BrandPicker__Item {
        flex: 1;
        height: fit-content;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
    .BrandSelector__Configuration__ColorPicker {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
    }
  }

  &--Flipped {
    transform: rotateY(180deg);
    // transform-style: preserve-3d;
    overflow: inherit;
  }
}
