$gray: #707070;
.TileSelect {
  width: 100%;
  margin-top: 5px;

  .TileSelect__Option {
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    height: fit-content;
    position: relative;

    color: #fff;
    // padding: 30px 0px 30px 20px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 15px;
    @media screen and (min-width: 1440px) {
      font-size: 20px;
      line-height: 28px;
    }
    @media screen and (max-width: 1600px) and (min-width: 1440px) {
      font-size: 22px;
      line-height: 30px;
    }
    @media screen and (max-width: 1920px) and (min-width: 1600px) {
      font-size: 24px;
      line-height: 32px;
    }

    span {
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
      z-index: 44;
    }

    &:after {
      background: rgba(0, 0, 0, 0.3);
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &--Active {
      border-color: #3ab85d;
      position: relative;
      &::after {
        background: rgba(58, 184, 93, 0.62);
      }
      span {
        z-index: 100000;
      }
    }
  }

  &.TileSelect--vertical {
    display: flex;
    text-align: center;
    height: fit-content;

    .TileSelect__Option {
      flex: 1;
      padding: 0px;
      font-size: 18px;
      line-height: 26px;
      margin: 0 15px;
      height: fit-content;
      overflow-y: none;

      img {
        width: 100%;
      }

      @media screen and (min-width: 1440px) {
        font-size: 20px;
        line-height: 28px;
      }
      @media screen and (max-width: 1600px) and (min-width: 1440px) {
        font-size: 20px;
        line-height: 30px;
      }
      @media screen and (max-width: 1920px) and (min-width: 1600px) {
        font-size: 21px;
        line-height: 32px;
      }

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.TileSelect--horizontal {
    .TileSelect__Option {
      width: 100%;
      position: relative;
      color: #fff;
      img {
        width: 100%;
      }
      span {
        position: absolute;
        top: 20px;
        left: 20px;
        bottom: 0;
        right: 0;
        margin: auto;

        @media screen and (min-width: 1440px) {
          font-size: 20px;
          line-height: 28px;
        }
        @media screen and (max-width: 1600px) and (min-width: 1440px) {
          font-size: 22px;
          line-height: 30px;
        }
        @media screen and (max-width: 1920px) and (min-width: 1600px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }
}
