$gray: #707070;
.IconSelect {
  width: 100%;

  .IconSelect__Option {
    transition: transform 0.8s;
    transform-style: preserve-3d;
    transform: rotateY(0deg);
    font-size: 20px;
    line-height: 33px;
    font-weight: 700;
    background: #fff;
    border: 1px solid #707070;
    color: #fff;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    width: 125px;
    height: 140px;
    display: inline-block;
    position: relative;
    
    
    margin: 10px;

    @media screen and (min-width: 1440px) {
      margin: 10px;
    }
    @media screen and (max-width: 1600px) and (min-width: 1440px) {
      margin: 15px 10px;
    }
    @media screen and (max-width: 1920px) and (min-width: 1600px) {
      margin: 20px 10px;
    }
    img {
      width: 40px;
      position: absolute;
      top: 5px;
      left: 5px;
    }
    span {
      position: absolute;
      bottom: 10px;
      left: 5px;
      right: 5px;
      color: #707070;
      font-size: 12px;
      line-height: 16px;

      @media screen and (min-width: 1440px) {
        font-size: 13px;
      }
      @media screen and (max-width: 1600px) and (min-width: 1440px) {
        font-size: 14px;
      }
      @media screen and (max-width: 1920px) and (min-width: 1600px) {
        font-size: 14px;
      }
    }

    .IconSelect__Option__Front {
      transform: rotateY(0deg);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      display: inline-block;
      background: #fff;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
    }

    .IconSelect__Option__Back {
      transform: rotateY(180deg);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      position: absolute;
      background: #fff;
      height: 100%;
      overflow-y: scroll;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: relative;
      span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &--Flipped {
      transform: rotateY(180deg);
    }

    &--Active {
      .IconSelect__Option__Front {
        span {
          color: #fff !important;
        }
        background: #3ab85d;
        border-color: #3ab85d;
      }
    }
  }
}
