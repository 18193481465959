.ColorPicker__Item {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  border: 2px solid transparent;
  margin: 0 10px;
  &.ColorPicker__Item--Active {
    border-color: #49bd69;
  }
}
