.QuestionDetails {
  .QuestionDetails__ImageContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }
  .QuestionDetails__Description {
    color: #707070;
    font-size: 18px;
    line-height: 26px;
  }
}
