.QuantityInput {
  display: flex;
  flex-direction: column;
  width: 100px;

  .QuantityInput__Label {
    font-size: 10px;
    font-weight: bold;
    text-align: center;
  }
  .QuantityInput__Controls {
    display: flex;
    align-items: center;
    justify-content: center;
    .QuantityInput__Controls__Input {
      flex: 1;
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
    }
    .QuantityInput__Controls__Btn {
      background: none;
      border: 0;
      width: 40px;
      height: 40px;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
