.FieldLabel {
    font-size: 12px;
    line-height: 16px;
    color: #3ab85d;
    font-weight: bold;
    @media screen and (min-width: 1440px) {
        font-size: 14px;
        line-height: 18px;
    }
    @media screen and (max-width: 1600px) and (min-width: 1440px) {
        font-size: 16px;
        line-height: 20px;
    }
    @media screen and (max-width: 1920px) and (min-width: 1600px) {
        font-size: 16px;
        line-height: 22px;
    }
}
