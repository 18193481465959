.StepItem {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f2;
  position: relative;
  height: 100%;

  &:first-child {
    margin-left: 30px;
  }
  &:last-child {
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
  }

  &.active {
    background: #3ab85d;
    .StepItem-Id {
      border: 2px solid #9ddba9;
    }
    .StepItem-Label {
      color: #fff;
      cursor: pointer;
    }
  }
  .StepItem-Id {
    position: absolute;
    left: -30px;
    width: 60px;
    height: 60px;
    border-radius: 200px;
    background: #fff;
    font-size: 20px;
    line-height: 27px;
    font-weight: bold;
    display: flex;
    align-items: center;
    border: 2px solid #ededed;
    justify-content: center;
    box-sizing: border-box;
  }

  .StepItem-Label {
    font-size: 20px;
    line-height: 27px;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    @media screen and (max-width: 1600px) and (min-width: 1440px) {
      font-size: 22px;
    }
    @media screen and (max-width: 1920px) and (min-width: 1600px) {
      font-size: 24px;
      margin: 10px 0;
    }
  }
}
