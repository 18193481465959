.StepContainer {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: calc(100% - 160px);
  max-width: 1440px;
  margin: 0 auto;

  &-action {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      appearance: none;
      height: 60px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-self: center;
      border: 2px solid #000;
      background: transparent;
      cursor: pointer;

      &:disabled {
        opacity: 0.2;
      }

      &[disabled] {
        cursor: not-allowed!important;
      }

      &.success {
        border-color: #3ab85d;
      }
    }
  }

  &-next {
    width: 80px;
  }

  &-prev {
    width: 80px;
  }

  &-content {
    flex: 1;
  }
}
