.card-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .card-controls {
    display: flex;
    flex-direction: column;

    button.card-controls-reset {
      appearance: none;
      background: #b4b4b4;
      border-radius: 50px;
      border: 0;
      color: #fff;
      width: 84px;
      height: 26px;
      text-align: center;
      margin: 0 auto 20px;
      cursor: pointer;
    }

    div {
      font-size: 13px;
      font-weight: 700;
      text-align: center;
      @media screen and (min-width: 1440px) {
        font-size: 13px;
        margin: 10px 0;
      }
      @media screen and (max-width: 1600px) and (min-width: 1440px) {
        font-size: 14px;
        margin: 10px 0;
      }
      @media screen and (max-width: 1920px) and (min-width: 1600px) {
        font-size: 16px;
        margin: 10px 0;
      }
    }

    button.withIcon span {
      font-size: 14px;
      @media screen and (min-width: 1440px) {
        font-size: 15px;
      }
      @media screen and (max-width: 1600px) and (min-width: 1440px) {
        font-size: 16px;
      }
      @media screen and (max-width: 1920px) and (min-width: 1600px) {
        font-size: 18px;
      }
    }
  }
}
