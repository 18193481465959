.QuizContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: stretch;

  .DeviceType {
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    @media screen and (min-width: 1440px) {
      font-size: 20px;
    }
    @media screen and (max-width: 1600px) and (min-width: 1440px) {
      font-size: 22px;
    }
    @media screen and (max-width: 1920px) and (min-width: 1600px) {
      font-size: 22px;
    }
  }

  & > div {
    &:first-of-type {
      margin-bottom: 10px;
    }
  }

  .Container {
    flex: 1;
    display: flex;
  }
}
