.AppHeader {
  // height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 50px;
  box-sizing: border-box;
  border-bottom: 1px solid #ededed;

  .logo {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      width: 200px;
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;

    select {
      margin-right: 10px;
    }

    .help {
      a {
        display: inline-block;
        // width: 100%;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
