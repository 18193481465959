.Card {
  flex: 1;
  max-width: 30%;
  border-radius: 30px;
  background: #f5f5f5;
  border: 1px solid #707070;
  padding: 20px;
  margin-right: 10px;
  margin-left: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 800px;

  &-title {
    line-height: 22px;
    font-size: 20px;
    min-height: 78px;
    margin: 0;

    @media screen and (min-width: 1440px) {
      font-size: 20px;
      line-height: 24px;
    }
    @media screen and (max-width: 1600px) and (min-width: 1440px) {
      font-size: 21px;
      line-height: 26px;
    }
    @media screen and (max-width: 1920px) and (min-width: 1600px) {
      font-size: 22px;
      line-height: 28px;
    }
  }

  &-subtitle {
    line-height: 14px;
    font-size: 10px;
    // min-height: 82px;
    color: #696969;
    margin: 0;
  }

  &-content {
    flex: 1;
    display: flex;
    overflow: hidden;
  }

  &-disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &--hidden {
    display: none;
  }
}
